import React from 'react'
import GoogleMap from "../GoogleMap";
import './ContacterSection.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faMapMarkerAlt, faPhoneAlt} from '@fortawesome/free-solid-svg-icons';
import Fade from 'react-reveal/Fade';

class ContacterSection extends React.Component {
    static defaultProps = {
        title: '',
        locations: {},
        phoneNumber: '',
        email: '',
        hours: []
    };


    render() {
        const {title, locations, phoneNumber, email, hours} = this.props;

        return (
            <div className="ContacterSection">
                <div className="mt-8 p-8 container text-center">
                    <Fade>
                        {title && <h2 className="ContacterSection--Title">{title}</h2>}
                        <hr></hr>
                    </Fade>

                    <Fade>
                        <div className="ContacterSection--Container flex flex-col md:flex-row mt-8">
                            <div className="ContacterSection--GoogleMaps w-full md:w-1/2 mb-6 md:mb-0">
                                <GoogleMap locations={locations}/>
                            </div>
                            <div
                                className="ContacterSection--Details w-full md:w-1/2 text-left md:pl-8 md:pr-8 text-neutral flex flex-col justify-center">
                                <p className="font-bold">Contactez notre secretariat pour toute prise de rendez-vous.</p>
                                <div className="flex flex-col md:flex-row">
                                    {phoneNumber && (
                                        <a className="ContacterSection--Details--Item mr-8 mb-4 md:mb-0 block"
                                           href={`tel:${phoneNumber}`}>
                                            <button className="Button"><FontAwesomeIcon icon={faPhoneAlt}
                                                                                        className="mr-2"/> {phoneNumber}
                                            </button>
                                        </a>
                                    )}
                                    {email && (
                                        <a className="ContacterSection--Details--Item block" href={`mailto:${email}`}>
                                            <button className="Button"><FontAwesomeIcon icon={faEnvelope}
                                                                                        className="mr-2"/> Nous écrire
                                            </button>
                                        </a>
                                    )}
                                </div>


                                <p className="mt-6 flex items-center font-bold"><FontAwesomeIcon icon={faMapMarkerAlt}
                                                                                                 className="text-3xl mr-2 text-black"/><a href="https://goo.gl/maps/MLg6di72TJwyv2o37" target="_blank">14
                                    rue d'Alicante 31600 Muret</a></p>


                                <div className="flex font-semibold mr-4 text-lg md:text-2xl">
                                    <ul className="mr-12">
                                        {hours && hours.map((h) => (
                                            <li>{h.day}</li>
                                        ))}
                                    </ul>
                                    <ul>
                                        {hours && hours.map((h) => (
                                            <li>{h.hours}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        )
    }
}

export default ContacterSection
