import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';

let mapkey = '';
if (process.env.NETLIFY_MAP_KEY) {
    mapkey = process.env.NETLIFY_MAP_KEY
}


class GoogleMap extends Component {
    static defaultProps = {
        center: {
            lat: 43.444593,
            lng: 1.3148899
        },
        zoom: 13
    };
    apiIsLoaded = (map, maps) => {
        let marker = new maps.Marker({
            position: this.props.center,
            map
        });
    };


    render() {


        return (
            // Important! Always set the container height explicitly
            <div style={{height: '350px'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: mapkey}}
                    defaultCenter={this.props.center}
                    center={this.props.center}
                    zoom={this.props.zoom}
                    ref={this.props.refs}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}
                    >
                </GoogleMapReact>
            </div>
        )
    }
}

export default GoogleMap
